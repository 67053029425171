.button {
    color: #fff;
    padding: 15px 50px;
    display: inline;
    border: none;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.button:hover {
    color: #fff;
    border: none;
    text-decoration: none;
}

.button.green {
    background-color: #58aa00;
}

.button.green:hover {
    background-color: #6cd100;
}
